import APIService from '@/services/APIService.js';

export default {
  getUsuarios() {
    return APIService.apiCall().get('/usuarios/get-usuarios');
  },
  alteraStatus(id) {
    return APIService.apiCall().post(`/usuarios/altera-status/${id}`);
  },
  save(postData) {
    return APIService.apiCall().post(
      '/usuarios/save',
      JSON.stringify(postData)
    );
  },
  salvarDashboard(dashboardId) {
    return APIService.apiCall().patch(
      `/usuarios/salvar-dashboard/${dashboardId}`
    );
  },
  updateUsuario(putData) {
    return APIService.apiCall().put(
      `/usuarios/update-usuario`, 
      JSON.stringify(putData)
    );
  }
};
