<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Salvar Usuário"
        subtitle="Atualize os dados do usuário do sistema, incluindo perfis de acesso."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content
              :userInfo.sync="userInfo"
              @data:updated="handleTabContentUpdated"
            />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content
            :acessos.sync="userInfo.acessos"
            @data:updated="handleTabContentUpdated"
          />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import UsuariosService from '@/services/UsuariosService';
import routes from '@/store/modules/routes';

export default {
  components: {
    MaterialWizard: () => import('@/components/base/MaterialWizard'),
    FirstTabContent: () =>
      import('@/components/usuarios/tabs/FirstTabContent.vue'),
    SecondTabContent: () =>
      import('@/components/usuarios/tabs/SecondTabContent.vue')
  },

  data: () => ({
    tab: 0,
    tabs: ['Informações básicas', 'Empresas x Perfis'],
    loading: false,
    userInfo: {
      nome: '',
      login: '',
      senha: '',
      email: '',
      tipo: '',
      cargo: '',
      diretoria: '',
      areaExecutiva: '',
      acessos: []
    }
  }),

  mounted() {
    if (this.usuarioId) this.getUsuario(this.usuarioId);
  },

  computed: {
    usuarioId() {
      return this.$route.params.id;
    },

    addMode() {
      return this.usuarioId === undefined;
    },

    availableSteps() {
      let steps = [];

      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }

      if (this.tab2IsValid) {
        steps.push(2);
      }

      return steps;
    },

    tab1IsValid() {
      const { nome, login, senha, email } = this.userInfo;

      // Validação de e-mail
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      return (
        nome.length &&
        login.length &&
        (senha.length || !this.addMode) &&
        email.length &&
        regex.test(email)
      );
    },

    tab2IsValid() {
      return this.userInfo.acessos.length > 0;
    },

    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;
      return true;
    }
  },

  methods: {
    async next(valid) {
      if (!valid) return;

      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }

      if (this.tab === 1) {
        if (!this.tab2IsValid) {
          return;
        }

        const sucesso = await this.salvarUsuario();
        if (!sucesso) {
          return;
        }

        this.$router.push({
          name: routes.state.appRoutes['USUARIOS_LISTAR']
        });
      } else {
        this.tab++;
      }
    },

    handleTabContentUpdated(event) {
      this.userInfo = Object.assign(this.userInfo, event);
    },

    getUsuario(usuarioId) {
      AuthService.getUserInfoById(usuarioId)
        .then((response) => {
          const {
            nome,
            login,
            email,
            tipo,
            cargo,
            diretoria,
            area_executiva: areaExecutiva,
            acessos
          } = response;

          this.userInfo = {
            ...this.userInfo,
            nome,
            login,
            email,
            tipo,
            cargo,
            diretoria,
            areaExecutiva,
            acessos
          };
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar os dados do usuário.', '', {
            position: 'topRight'
          })
        );
    },

    async salvarUsuario() {
      let sucesso = false;
      this.loading = true;

      try {
        let data = {
          ...this.userInfo,
          id: this.usuarioId ? this.usuarioId : null
        };

        sucesso = await UsuariosService.save(data);

        this.$toast.success('Usuário salvo com sucesso.', '', {
          position: 'topRight'
        });
      } catch (e) {
        this.$toast.error('Erro ao salvar o usuário.', '', {
          position: 'topRight'
        });
        console.log(e);
      } finally {
        this.loading = false;
      }

      return sucesso;
    }
  }
};
</script>
